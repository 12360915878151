import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../../components/news-strip"

class NewsPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Tetley's Coaches' Operator Licence increased to 60 Vehicles" description="Tetley's Coaches are delighted to announce that The Traffic Commissioner has granted an increase in Operator Licence from 50 to 60 vehicles." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Tetley's Coaches' Operator's Licence increased to 60 Vehicles</h1>
              <p className="news-article-date">December 2015</p>
            </div>

            <div className="news-article-body">
              <p>Tetley's Coaches are delighted to announce that The Traffic Commissioner has granted us an increase in our Operator's Licence from 50 to 60 vehicles and enable us to continue to build our business and serve a loyal and growing customer base.</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default NewsPage
